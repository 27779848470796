import initFetchPonyfill from 'fetch-ponyfill';
import OneXpSdkClient from './sdk';
import promisePonyfill from './promisePonyfill';

const initialize = ({
  fetchClient: userProvidedFetchClient,
  oneXPBaseURL = process.env.ONE_XP_BASE_URL,
  oneXPFaasURL = process.env.ONE_XP_FAAS_URL,
} = {}) => {
  if (!oneXPBaseURL || typeof oneXPBaseURL !== 'string') {
    throw new Error(`Invalid param oneXPBaseURL: expected string but received ${oneXPBaseURL}`);
  }

  if (!oneXPFaasURL || typeof oneXPFaasURL !== 'string') {
    throw new Error(`Invalid param oneXPFaasURL: expected string but received ${oneXPFaasURL}`);
  }

  const fetchClient = userProvidedFetchClient
    || initFetchPonyfill({ Promise: promisePonyfill }).fetch;

  return new OneXpSdkClient({ fetchClient, oneXPBaseURL, oneXPFaasURL });
};

export default initialize;

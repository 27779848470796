// an isomorphic base64 decoding pattern modified from:
// https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding#Solution_4

export const parseQueryString = (query) => {
  const vars = query.split('&');
  const params = {};
  for (let i = 0; i < vars.length; i += 1) {
    const pair = vars[i].split('=');
    const key = decodeURIComponent(pair[0]);
    const value = decodeURIComponent(pair[1]);
    // Ignore duplicate URL params; assume the first instance is authoritative
    if (typeof params[key] === 'undefined') {
      params[key] = decodeURIComponent(value);
    }
  }
  return params;
};

export const getVariantsFromUrl = (locator) => {
  try {
    const search = locator.split('?')[1] || '';
    const searchParams = parseQueryString(search);
    const forcedVariantsEncoded = searchParams.oneXPForcedExperiments;
    if (!forcedVariantsEncoded) {
      return undefined;
    }
    const stringFromData = (typeof window !== 'undefined')
      ? atob(forcedVariantsEncoded)
      : Buffer.from(forcedVariantsEncoded, 'base64').toString();

    const stringifiedObject = decodeURIComponent(
      stringFromData
        .split('')
        .map(character => `%${(`00${character.charCodeAt(0).toString(16)}`).slice(-2)}`)
        .join('')
    );
    const parsed = JSON.parse(stringifiedObject);
    return typeof parsed === 'object' ? parsed : undefined;
  } catch (e) {
    return undefined;
  }
};

export const formatForcedVariants = (experimentData) => {
  const experimentsArray = [];
  Object.keys(experimentData).forEach((experiment) => {
    let id;
    const experimentValues = Object.keys(experimentData[experiment]);
    const dimensions = [];
    experimentValues.forEach((name) => {
      const variant = experimentData[experiment][name];
      // eslint-disable-next-line no-unused-expressions
      name === 'experimentId'
        ? id = variant
        : dimensions.push({ name, variant });
    });

    experimentsArray.push({
      name: experiment,
      id,
      dimensions,
      variantForced: true,
    });
  });
  return experimentsArray;
};

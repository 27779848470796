import deepmerge from 'deepmerge';
import Promise from './promisePonyfill';

const getFetchOptions = ({ fetchOpts, payload, experimentData }) => deepmerge(
  fetchOpts,
  {
    body: JSON.stringify(payload),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...experimentData,
    },
    method: 'POST',
  }
);

const makeRequest = ({
  fetchClient, fetchOpts, url, payload, experimentData,
}) => {
  const fetchOptions = getFetchOptions({ fetchOpts, payload, experimentData });
  return fetchClient(url, fetchOptions)
    .then((response) => {
      // overt error: bad HTTP response
      if (!response.ok) {
        return response.text()
          .then(
            text => Promise.reject(new Error(`STATUS ${response.status}: ${text}`)),
            error => Promise.reject(error)
          );
      }
      return response.text();
    })
    .then((text) => {
      if (text === '') {
        return {};
      }
      return JSON.parse(text);
    })
    // service-level error reported in the response body
    .then((response) => {
      if (response.status && response.status >= 400) {
        const error = response.error
          ? new Error(response.error)
          : new Error(JSON.stringify(response));
        error.status = response.status;
        return Promise.reject(error);
      }
      return response;
    });
};

export default makeRequest;

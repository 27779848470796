import deepmerge from 'deepmerge';
import makeRequest from './makeRequest';
import { getVariantsFromUrl, formatForcedVariants } from './forcedVariantUtils';
import Promise from './promisePonyfill';

class OneXpSdkClient {
  constructor({ fetchClient, oneXPBaseURL, oneXPFaasURL }) {
    this.fetchClient = fetchClient;
    this.oneXPBaseURL = oneXPBaseURL;
    this.oneXPFaasURL = oneXPFaasURL;
    this.userIdentifier = null;
  }

  /**
   * @description Finds experiments a user is eligible for and assigns them
   * @param {Object} params
   * @param {string} params.locator The URL to detect eligible experiments on
   * @param {Object} [params.fetchOpts] Additional options to include with the fetch request
   * @param {Object} params.oneXPForcedExperiments An object representing the
   * experiments/variantsdesired. Will override the return value of the request.
   * @returns {Promise<Array<Experiment>>} An array of experiment objects
   */
  findVariants({
    locator,
    oneXPForcedExperiments,
    experimentData = {},
    fetchOpts = {},
  }) {
    const forcedVariants = oneXPForcedExperiments || getVariantsFromUrl(locator);
    if (typeof forcedVariants === 'object') {
      const formattedExperiments = formatForcedVariants(forcedVariants);
      return Promise.resolve(formattedExperiments);
    }

    const payload = {
      locator,
    };

    return makeRequest({
      fetchClient: this.fetchClient,
      fetchOpts: deepmerge(
        fetchOpts,
        this.userIdentifier ? { headers: { 'agent-id': this.userIdentifier } } : {}
      ),
      url: `${this.oneXPBaseURL}/variant/find`,
      payload,
      experimentData,
    })
      .then(({ experiments, userIdentifier }) => {
        if (userIdentifier && typeof userIdentifier === 'string' && !this.userIdentifier) {
          this.userIdentifier = userIdentifier;
        }
        return experiments;
      })
      .catch((e) => {
        if ((e.status && e.status === 404) || (e.message && e.message === 'AGENT_ID_MISSING')) {
          return [];
        }
        return Promise.reject(e);
      });
  }

  /**
   * @description Sends impressions for a list of experiments
   * @param {Object} params
   * @param {Array<Object>} params.experiments Array of objects with experimentID and variants
   * @returns {Promise<void>}
   */
  impressVariants({ experiments, fetchOpts = {} }) {
    // TODO: API expects an object called `variants`; this is a confusing name. `variants` is a map
    // of dimension names to values; it represents a particular group a user has been assigned to.
    const payload = experiments.map(({ experimentId, variants }) => ({
      experimentId,
      variants,
    }));

    return makeRequest({
      fetchClient: this.fetchClient,
      fetchOpts,
      url: `${this.oneXPFaasURL}/UpdateOneXPVariantImpressed.v1`,
      payload,
    }).then(() => {}); // swallow the response data, since it isn't actually needed
  }

  /**
   * @description Sends an action for a particular action
   * @param {Object} params
   * @param {string} params.experimentId Identifier of the experiment to emit action on
   * @param {string} params.objectiveId Identifier of the objective that had an action
   */
  emitObjectiveAction({ experimentId, objectiveId, fetchOpts = {} }) {
    const payload = {
      experimentId,
      objectiveId,
    };

    return makeRequest({
      fetchClient: this.fetchClient,
      fetchOpts,
      url: `${this.oneXPFaasURL}/CreateOneXPAction.v1`,
      payload,
    }).then(() => {}); // swallow the response data, since it isn't actually needed
  }
}

export default OneXpSdkClient;
